@media (min-width: 768px) {

  .topbar {
    width: auto !important;
  }

}

.navbar {
  max-width: 1140px;
}

.navbar-toggler {
  border: 0 solid transparent;

  &:focus {
    outline: none;
  }
}
