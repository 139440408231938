@import './styles/theme.scss';

html {
  font-size: 16px;
}

body {
  padding-top: 80px;
  max-width: 1140px;
  margin-left: auto;
  margin-right: auto;
  min-height: 100vh;
}

.overflow-hidden {
  overflow: hidden;
}

.flex-equal > * {
  -ms-flex: 1;
  -webkit-box-flex: 1;
  flex: 1;
}

a {
  text-decoration: none;

  &:hover,
  &:focus {
    text-decoration: none;
  }
}

h1 {
  margin-top: 16px;
}

@media (min-width: 768px) {
  .flex-md-equal > * {
    -ms-flex: 1;
    -webkit-box-flex: 1;
    flex: 1;
  }

  .mw-50 {
    max-width: 50%;
  }
}
